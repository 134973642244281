import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="New release updates | GlassHive"
                description="At GlassHive, we are continuously making improvements to the platform to enhance user experience and functionality. Check out the newest features!"
            />
            <div className="release-notes-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                GlassHive new release updates
                            </h1>
                            <p className="hero-subtext white-text">July 2022</p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/4jIg7U1by-Y"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                The latest updates from GlassHive
                            </h4>
                            <p>
                                It's been awhile since our last one but even
                                though we're a bit behind with our video
                                releases, it doesn't mean we're behind on the
                                work to make the app simple and fun. In today's
                                video we'll be discussing a few changes we've
                                made to GlassHive's interface, some overall
                                improvements to streamline your activity, and a
                                new feature you're going to love. Let's get into
                                it!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">New features</h4>
                            <ul>
                                <li>
                                    Users can now create landing page templates
                                </li>
                            </ul>

                            <h4 className="centered-mobile">
                                Updates & Improvements
                            </h4>
                            <ul>
                                <li>
                                    [Landing Page Builder] Users now have the
                                    ability to add links to images
                                </li>
                                <li>
                                    Company Details logo, Contact Details pic,
                                    and Company on Contact Profile now look the
                                    same
                                </li>
                                <li>
                                    URL and checkbox custom fields can now be
                                    clicked on the contact & company profile
                                </li>
                                <li>
                                    Users can now see how many times they have
                                    sent their collateral in emails
                                </li>
                                <li>
                                    Users can now search for fields when mapping
                                    their columns during list upload
                                </li>
                                <li>Updated forms integration icon</li>
                                <li>Updated Automations icon on the nav</li>
                                <li>
                                    Added Unsubscribed column to contacts table
                                </li>
                                <li>
                                    Add View link in notification when a user
                                    publishes a LP
                                </li>
                                <li>
                                    Force Journeys to have a name on create/edit
                                </li>
                                <li>
                                    Activities headshot and icons size is now
                                    consistent in Contact Details page
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Bug fixes</h4>
                            <ul>
                                <li>
                                    Fixed bug where dragging elements on
                                    collateral builder could break the rendering
                                </li>
                                <li>
                                    Fixed issue where the client's next billing
                                    date was incorrect
                                </li>
                                <li>
                                    Fixed issue where company type dropdown was
                                    showing incorrect company types
                                </li>
                                <li>
                                    Fixed bug where multicheckbox fields
                                    wouldn't update their labels appropriately
                                </li>
                                <li>
                                    Fixed bug where Collateral Builder was
                                    breaking when you set up your LP branding
                                </li>
                                <li>
                                    Fixed bug where notification wouldn't get
                                    sent out if an opportunity was marked as won
                                    on the table
                                </li>
                                <li>
                                    Fixed bug where UI wouldn't inform users
                                    when they are overdue on payment
                                </li>
                                <li>
                                    Fixed bug when your timezone is set to
                                    UTC+5:30 timezone
                                </li>
                                <li>
                                    Fixed bug where you couldn't see the proper
                                    font styles on LP text element after you
                                    made changes
                                </li>
                                <li>
                                    Fixed bug where paragraph text in campaigns
                                    didn't default to left align
                                </li>
                                <li>
                                    Fixed error when duplicating Unpublished
                                    Non-Graphical Templates as a Vendor
                                </li>
                                <li>
                                    Pop up menu now closes when deleting
                                    Unpublished Non-Graphical Templates as a
                                    Vendor
                                </li>
                                <li>
                                    Opened/Clicked Email filter on Contact
                                    Details page now works correctly
                                </li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free account today!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
